/*Card__service*/
.container__card{
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
    padding: 0 10px;
    width: 90%;
    margin: 50px;

    @include mobile {
        flex-direction: column;
        align-items: center;
    }
}
.card-link {
    text-decoration: none;
    width: 30%;

    @include mobile {
        width: 300px;
    }
  }
  
.card {
    display: flex;
    flex-direction: column;
    background-color: #002A3B;
    color: #a7d403;
    border-radius: 10px;
    transition: transform 0.3s;
    box-shadow: $box-shadow-card;
    &:hover{
        transform: scale(1.05);
        p{color: $blue;
        opacity: 0.7;}
    }

    &__image {
        width: 100%;
        height: 150px;
        border-radius: 10px 10px 0 0;
        object-fit: cover;

        @include mobile {
            height: 150px;
            width: 300px;
        }
    }

    &__content {
        text-align: center;
        padding: 10px;

        p {
            color: white;
            opacity: 1;
            @include mobile {
                margin: 0;
            }
        }
    }
}

/*Card Icon*/

.savoirFaire-card{
    .card-link{
        width: 30%;

        @include mobile {
            width: 70%;
        }
        .card {
            align-items: center;
            @include mobile {
                flex-direction: row;
            }
        }
        .card-span {
            border-radius: 50%;
            background-color: #a7d403;
            color: #002A3B;
            width: 70px;
            height: 70px;
            display: flex;
            justify-content: center;
            flex-direction: column;
            position: relative;
            top: -35px;
            left: auto;
            font-size: 30px;

            @include mobile{
                top:0;
                left:-30px;
            }

        }
    }
}