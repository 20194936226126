/*Style body*/

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');


body {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    color: $darkgreen;

}

section {
    margin-top: 50px;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

h1 {
    font-size: 36px;
    width: 60%;
    margin: 20px 0;
    @include mobile {
        font-size: 24px;
    }
}

h2 {
    color: $darkgreen;
    display: flex;
    justify-content: center;
    font-size: 36px;
    text-align: center;
    margin: 0 10px;

    @include mobile {
        font-size: 20px;
    }
}  
   
h3 {
    margin: 0;
}

.section-text {
    margin: 50px 10px 50px 10px;

    @include mobile {
        font-size: 16px;
        margin: 30px 20px 30px 20px;
    }
}

p {
    text-align: center;
    opacity: 0.75;
}



.container{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.container__body{
    display: flex;
    flex-direction: column;
    max-width: 1440px;
}
