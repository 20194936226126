.gallery__section{
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  button::after{
    border-radius: 5px;
  }
}

.gallery {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px;
  border-radius: 20px;
  max-width: 1200px;
  
  &__card {
    display: flex;
    flex-direction: column;
    max-width: 350px;
    background-color: white;
    border-radius: 15px;
    transition: 0.3s;
    box-shadow: $box-shadow-card;
  
    &:hover {
      transform: scale(1.05);
    }
  
    &-img {
      object-fit: cover;
      border-radius: 15px 15px 0 0;
      height: 150px;
    }

    &-content {
      text-align: center;
      background-color: $darkgreen;
      border-radius: 0 0 15px 15px;

      p {
        color: $green;
        opacity: 1;
      }
    }
  }
}
  
  /* Style pour la superposition du carrousel */
.slideshow-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  cursor: pointer;
  button::after{
    border-radius: 5px;
  }
}

/* Style pour le bouton de fermeture du carrousel */
.close-button {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  top: 20px;
  right: 20px;
  background-color: $darkgreen;
  color:$green;
  border: none;
  cursor: pointer;
  outline: none;
  border-radius: 5px;
}
  
.close-button span {
  border-radius: 0px;
  font-size: 32px;
    button::after{
  border-radius: 5px;
  }
}
  
/* Style pour le conteneur du carrousel */
.carousel-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.carousel-arrow-container{
  display: flex;
  justify-content: center;
  gap: 10px;
}

/* Style pour les flèches du carrousel */
.carousel-arrow {
  bottom: -40px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 30px;
  color: $green;
  border: none;
  cursor: pointer;
  outline: none;
  border-radius: 5px;
  background-color: $darkgreen;
  box-shadow: $box-shadows-hover;
}
  
.carousel-arrow span {
  font-size: 32px;
  font-weight: bold;
}

.carousel-root{
  max-width: 80%;
}

.carousel .selected {
  height: 100%;
  .test{
    width: 100%;
  }
}