/*Style pour page notre savoir-faire*/
#chauffage {
    flex-direction: row-reverse;

    .savoirFaire-div{
        padding-left: 0;

        @include mobile {
            padding-left: 20px;
        }
    }
    
    @include mobile {
        flex-direction: column;
    }
}

.savoirFaire-section {
    display: flex;
    flex-direction: row;
    margin: 50px;

    @include mobile {
        flex-direction: column;
        margin: 50px 0;
    }

    .service-img{
        width: 50%;

        @include mobile {
            width: calc(100% - 20px);
            padding: 0 10px
        }

    }

    .savoirFaire-div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0 20px;

        @include mobile{
            width: auto;
            margin-top: 30px;
        }

        h2 {
            text-align: left;
            margin: 0;
        }
        p {
            text-align: left;
        }

        ul {
            list-style: none;

            @include mobile{
                margin-left: 0;
                padding-left: 0;
            }
            
            li {
                display: flex;
                align-items: flex-start;

                .check {
                margin-right: 10px;
                color: $green;
                }

                p{
                    opacity: 1;
                    margin: 0;
                }
            }
        }
        button {
            left: 60px;
        }
    }
}