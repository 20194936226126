/* Style pour le formulaire */

#form__section {
    display: flex;
    justify-content: center;
}
.contact__form {
    background-color: $darkgreen;
    border-radius: 5px;
    width: 80%;
    box-shadow: $box-shadow-card;
    padding: 30px 0;
    
    @include mobile{
        width: 100%;margin-bottom: 30px;
    }

    h2 {
    color: $green;
    }
}
.form {
    @include form;
    width: calc(100% - (40px));

    @include mobile {
        flex-direction: column;
        align-items: center;
    }

    &-group{
        display: flex;
        flex-direction: column;
        width: calc(50% - (10px));

        @include mobile {
            width: 100%;
        }

        label {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            font-weight: bold;
            margin-bottom: 8px;
            opacity: 0.7;
          }

        input[type="text"],
        input[type="email"],
        input[type="tel"],
        select,
        textarea {
        
            padding: 10px;
            margin-bottom: 15px;
            border: 1px solid #ccc;
            border-radius: 5px;
            font-size: 16px;
        }
    }

    .form-style{
        width: 100%;
    }
}

/*Style Devis Form*/
.container__devis {
    display: flex;

    @include mobile {
        flex-direction: column;
    }

    .devis__content {
      display: flex;
      align-items: center;
      flex-direction: column;
      margin: 0 50px;

        @include mobile{
            flex-direction: column-reverse;
            margin: 0 10px;
        }

      &-text {
        margin: 0;
          p {
              text-align: left;
              opacity: 1;

              @include mobile {
                text-align: center;
              }
          }
      }
      &-img {
          max-width: 60%;
          min-width: 250px;
      }
    }
    .devis__container-form {
        width: 50%;
        @include form;
        margin: 0 50px 0 0;
        box-shadow: $box-shadow-card;
        
        @include mobile {
            width: 100%;
            margin: 0 0 30px 0;
            padding: 20px 0;
        }
    }
    
    .devis__form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 80%;
        padding: 20px 0;
        
        &-msg{
            display: flex;
            flex-direction: column;
            label{
                margin-bottom: 8px;
            opacity: 0.7;
            }
            #message {
                min-height: 150px;

            }
        }

        &-questions {
            display: flex;
            flex-direction: column;
            margin-top: 20px;
            label {
                margin-bottom: 8px;
                opacity: 0.7;
                select {
                    height: 40px;
                }
            }
        }
        &-title  {
            text-align: center;
        }
    }
  }

  #devis__form-group{
    width: 100%;
  }

/* Style pour les messages d'erreur */
  .error-message {
    color: #ff0000;
    font-size: 14px;
    margin-top: 5px;
  }

.confirmation-message{
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $green;
  padding: 20px;
}

#question-1, #question-2, #question-3 {
    height: 30px;
}