.loading_spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background:$darkgreen; 
    animation: 2s animateBg linear;
    animation-fill-mode: forwards;
    visibility: hidden;
    position: fixed;
    width: 100vw;
    z-index: 999;
}

   
@keyframes animateBg {
    0% {
        filter: hue-rotate(0deg);
        visibility: visible;
    }
    99.9% {
           filter: hue-rotate(-90deg);
    }
    100% {
        visibility: hidden;
    }
}

.loader {
    position: relative;
    width: 120px;
    height: 120px;

    span {
        position: absolute;
        top: 0%;
        left: 0;
        width: 100%;
        height: 100%;
        transform: rotate(calc(18deg * var(--i)));
    }
}  

.loader span::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: $blue;
    box-shadow: 0 0 10px $blue;
    box-shadow: 0 0 20px $blue;
    box-shadow: 0 0 40px $blue;
    box-shadow: 0 0 60px $blue;
    box-shadow: 0 0 80px $blue;
    box-shadow: 0 0 100px $blue;
    animation: 1s animate linear infinite; 
    animation-fill-mode: forwards;
    animation-delay: calc(0.1s * var(--i)); 
}

@keyframes animate {
    0% {
        transform: scale(1);
    }
    80%,100% {
        transform: scale(0);
    }
}