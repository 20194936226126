.maintenance__div{
    display: flex;
    flex-direction: column;
     align-items: center;
     margin-top: 50px;

    &-img{
        width: 70%;
    }

    &-text {
        text-align: center;
        opacity: 1;
        font-size: 36px;
        font-weight: bold;
        margin-top: 50px;
        opacity: 0;
        animation: fadeIn 6s ease-in-out infinite; 

        @include tablet{
            font-size: 24px;
        }

        @include mobile{
            font-size: 18px;
        }
    
    .maintenance__div-title{
        opacity: 0; 
        animation: fadeIn 3s ease-in-out forwards; // Animation de fondu en 2 secondes, répétée à l'infini
    }

    .maintenance__div-subtitle{
        font-size: 18px;
        opacity: 0; // Commencez avec une opacité de 0
        animation-delay: 0.4s;

        @include tablet{
            font-size: 14px;
        }
    }
        
    p {
        margin: 0;
        opacity: 0; // Commencez avec une opacité de 0
        animation: fadeIn 3s ease-in-out infinite; // Animation de fondu en 2 secondes, répétée à l'infini
    }

    }
}

@keyframes fadeIn {
    0% {
      opacity: 0; // Commencez avec une opacité de 0
    }
    70% {
      opacity: 1; // Atteindre une opacité de 1 à mi-chemin de l'animation
    }
    100% {
      opacity: 1; // Revenir à une opacité de 0 à la fin de l'animation
    }
  }