@import './utils/_variables';
@import './utils/_mixins';
@import './utils/_typographie';
@import './bases/body';
@import './pages/accueil';
@import './pages/contact';
@import './pages/devisenligne';
@import './pages/nosrealisations';
@import './pages/notresavoirfaire';
@import './pages/pompeachaleur';
@import './components/loading_spinner';
@import './components/header';
@import './components/footer';
@import './components/menu';
@import './components/banner';
@import './components/button';
@import './components/card';
@import './components/slide';
@import './components/gallery';
@import './components/form';
@import './components/maintenance_page';
@import './components/faq';

/*Quel emplacement*/
.sr-only {
    display: none;
}