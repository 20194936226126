  /*Label Marque*/
  .container__label{
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;

    .label{
        width: 200px;
        padding: 20px;
        transition: transform 0.3s ;
        &:hover {
            transform: scale(1.10);
        }

        img{
            width: 100%;
            height: auto;
        }
    }
}

.link-bold {
    text-decoration: none;
    font-weight: 800;
    color: $darkgreen;

    &:hover{
        color: $blue;
    }
}