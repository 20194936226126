.banner {
    height: 400px;
    width: 100%;
    background-color: $green;

    @include mobile {
        height: 300px;
    }

    &-image{
        width: 100%;
        height: 100%;
        opacity: 0.9;

    }

    &-content{
        position: relative;
        left: 50px;
        bottom: 370px;
        width: 80%;
        
        @include mobile {
            bottom: 270px;
        }
        p{
            text-align: left;
            font-size: 96px;
            color: #002A3B;
            opacity: 1;
            font-weight: bold;
            margin: 0;
            
            @include mobile{
                font-size: 36px;
            }
            animation: fadeIn 1.5s ease-in-out forwards;
        }
        h1 {
            text-align: left;
            font-size: 36px;
            color: #002A3B;
            font-weight: bold;

            @include mobile {
                font-size: 20px;
            }

            /* Animation CSS pour le fondu */
            animation: fadeIn 1.5s ease-in-out forwards;
            animation-delay: 0.5s; /* 1.5s est la durée de l'animation, ajustez selon vos besoins */
        }


        @keyframes fadeIn {
            from {
                opacity: 0;
            }
            to {
                opacity: 1;
            }
        }

    }
}
