.header {
    @include container;
    margin-bottom: 0;

    &__contact{
        margin: 10px 20px;
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;

        @include tablet {
            width: 100%;
            background-color: $darkgreen;
            margin: 0;
        }

        &-details {
            display: flex;
            justify-content: space-between;
            gap: 20px;

            @include tablet {
                flex-direction: column;
                align-items: flex-start;
                padding: 10px;
                gap: 10px;
            }

            a {
                justify-content: flex-end;
                align-items: center;
                text-decoration: none;
                display: flex;
                color: $darkgreen;
                gap: 10px;

                &:hover {
                    color: $blue;
                    transform: scale(1.10);
                }

                @include tablet {
                    color: $green;
                }
            }
        }

        &-media {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 20px;

            @include tablet {
                justify-content: flex-end;
                padding: 10px;
                align-items: flex-start;
            }

            a {
                color: $darkgreen;
                transition: transform 0.3s;

                @include tablet {
                    color: $green;
                }

                &:hover {
                    color: $blue;
                    transform: scale(1.30);
                }
            }
        }
    }
}

.nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 60px;

    .logo {
        margin-left: 30px;
    }
}