#faq {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    @include mobile{
      align-items: center;
    }

    h2{
      margin: 0 50px;
    }
  }
    .faq__container {
      margin: 50px;
      display: flex;
      flex-direction: column;
      width: 80%;
      border-radius: 10px;
    
      @include mobile{

        margin-top: 30px;
        width: 90%;

      }
    }
      
    
      .faq__card {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        width: 100%;
        margin: 20px 0;
        color: $green;
        border-radius: 5px;
        background: $darkgreen;
  
        @include mobile{
          margin: 20px 0;
          padding: 20px 0;
        }
  
  
        &-title{
          display: flex;
          width: calc(100% - 40px);
          height: 50px;
          margin-left: 20px;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;
          h3{
            font-weight: 400;
            color: white;
            margin: 0 0 0 30px;
  
            @include mobile{
              margin: 10px;
              font-size: 16px;
            }
          }
        }
  
        &-text{
          text-align: left;
          width: calc(100% - 140px);
          border-radius: 0 0 5px 5px;
          opacity: 0.7;
          color: white;
          margin: 10px 0 20px 90px;
  
          @include mobile{
            margin: 30px 0 0 10px;
            width: calc(100% - 30px);
          }
  
          p{
            opacity: 1;
            text-align: left;
            margin: 0 0 10px 0;
          }
        }
      }
  
  
  
  .faq__card-icon{
    font-size: 35px;
    width: 40px;
    color: $green;
  }
  
  .card__title-div{
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .fa-chevron-down{
    color: $green;
  }
  
  .container__processus{
    p{
      margin: 10px;
    }
  }