/*Style page Pompe à chaleur*/
.pac-img{
    width: 60%;

    @include mobile {
        width: 100%;
    }
}

.pac-lien{
    width: 40%;

    @include mobile{
        width: 100%;
    }
}

.pac-img-test{
    width: 100%;
}

#pac__section{
    .savoirFaire-div{
        padding-left: 20px;
        width: 40%;

        @include mobile{
            width: auto;
            padding: 20px;
        }
    }
}

#certification__section{
    flex-direction: row-reverse;
    align-items: center;

    @include mobile {
       flex-direction: column;
       width: auto;
       padding: 20px;
    }

    .savoirFaire-div{
        padding-left: 0;
        width: 60%;

        @include mobile{
            width: 100%;
            padding: 0 20px;
        }
    }

}

#installation__section {
    flex-direction: column;
    
    @include mobile {
        padding: 20px
    }

    .savoirFaire-div{
        padding-left: 20px;
        //width: 40%;

        @include mobile{
            padding: 0;
            width: 100%;
        }
}
}

#process__section{
    flex-direction: column;
    background: $darkgreen;
    padding: 50px;

    @include mobile{
        padding: 20px;
    }
    .savoirFaire-div{
        color: white;
        width: 100%;
        margin-bottom: 50px;

        h2{
            color: white;
        }
    }
    button{
        background: linear-gradient(to bottom right, lighten($green, 5), lighten($darkgreen, 5));
        color: $darkgreen;
        margin-top: 30px;
    }
}

#operation__section{
    .savoirFaire-div{
        width: 40%;

        @include mobile{
            width: auto;
            padding: 20px;
        }
    }
}

.installation__section-header {
    display: flex;
    flex-direction: row-reverse;

    @include mobile {
        flex-direction: column;
    }
}

.installation__container {
    margin-top: 50px;
    
    &-div{
        margin-bottom: 50px;
        p{
            text-align: left;
        }
    }

    &-img{
        width: 100%;
        display: flex;
        justify-content: center;
        background: $darkgreen;

        img{
            width: 60%;

            @include mobile {
               width: 90%;
            }
        }
    }
}

.video-wrapper{
    width: 60%;

    @include mobile {
        width: 100%;
    }
}





